
import { OfferDto } from '@/dtos/offerDto';
import { Component, Vue } from 'vue-property-decorator';
import { PriceModel } from '@/dtos/priceModel';
import OfferComponent from '@/views/Checkout/Offer.vue';

@Component({
    components: {
        OfferComponent
    }
})
export default class SummaryComponent extends Vue {

    private selectedPriceModel: PriceModel | null = null;

    private mounted() {
        if (this.currentOffer && this.currentOffer.orderDiscounts.length == 1) {
            // Wenn es nur ein Angebot gibt, muss das ausgewählt werden.
            ///@ts-ignore
            this.$refs[this.currentOffer.orderDiscounts[0].priceModel][0].selected = true;
            ///@ts-ignore
            this.$refs[this.currentOffer.orderDiscounts[0].priceModel][0].anySelected = true;
            this.selectedPriceModel = this.currentOffer.orderDiscounts[0].priceModel;
        }
    }

    /**
     * Eventhandler wenn ein Angebot ausgewählt wird.
     * Wählt alle anderen Angebote ab und teilt ihnen mit
     * dass es jetzt ein ausgewähltes Angebot gibt.
     */
    private changeSelection(priceModel: PriceModel) {
        this.selectedPriceModel = priceModel;
        for (const discount of this.currentOffer.orderDiscounts) {
            // Alle anderen werden deselektiert.
            if (discount.priceModel != priceModel) {
                ///@ts-ignore
                this.$refs[discount.priceModel][0].selectedOfferChanged();
            }
        }
    }

    private get currentOffer() {
        return this.$store.state.offer as OfferDto;
    }
    
}
