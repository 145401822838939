
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FertigerAddressDto } from '@/dtos/fertigerAddressDto';
import httpClient from '@/store/httpClient';

@Component({})
export default class Offer extends Vue {
    private areFertigerAddressesLoading: boolean = false;
    private isFertigerAddressesLoadingFailed: boolean = false;
    private manufacturerAddresses: FertigerAddressDto[] = [];
    private isManufacturerAddressDialogVisible: boolean = false;
    private isManufacturerNotPossibleDialogVisible: boolean = false;
    private currentFertigerAddress: FertigerAddressDto = new FertigerAddressDto();
    private rampeManufacturerId: number = -1;

    private mounted() {
        this.loadFertigerAddresses();
        this.setRampenabholungState();
    }

    @Watch('rampeManufacturerId')
    private onRampeSelectionChanged() {
        if (this.rampeManufacturerId > -1) {
            this.selectedManufacturerId = this.rampeManufacturerId;
        }
    }

    private async loadFertigerAddresses(){
        try {
            this.isFertigerAddressesLoadingFailed = false;
            this.areFertigerAddressesLoading = true;
            const response = await httpClient()
                .get('manufacturer/GetManufacturerAddresses?regionId=' + this.$store.state.customer.regionId);
            this.manufacturerAddresses = response.data;
        } catch(err) {
            console.log(`Fertigeradressen konnten nicht geladen werden. ${err}`);
            this.isFertigerAddressesLoadingFailed = true;
        } finally {
            this.areFertigerAddressesLoading = false;            
        }
    }

    private get selectedManufacturerId() {
        return this.$store.state.offer.selectedManufacturerId;
    }
    private set selectedManufacturerId(manufacturerId: number){
        this.$store.commit('changeSelectedManufacturer', manufacturerId);
        this.setRampenabholungState();
    }
    
    private get deliveryChoice() {
        return this.$store.state.offer.deliveryChoice;
    }
    private set deliveryChoice(value: string) {
        this.$store.dispatch('setOfferDeliveryChoice', value);
        if (!this.isRampenabholung) {
            this.rampeManufacturerId = -1;
        }
        this.setRampenabholungState();
    }

    private get isRampenabholung(): boolean {
        return this.deliveryChoice === 'rampe';
    }

    private get manufacturingOffers() {
        return this.$store.state.offer.manufacturingOffers;
    }
    
    private get isValidSelection() {
        return this.$store.state.rampenabholungSelectionValid;
    }

    private get selectedFertigerName() {
        var address = this.manufacturerAddresses
            .find((addr: FertigerAddressDto) => addr.manufacturerId === this.selectedManufacturerId);
        if (address === undefined) {
            return this.selectedManufacturerId.toString();
        }
        return address.companyname;
    }

    private get isAdmin() {
        return this.$store.state.customer.user.isAdmin;
    }

    private setRampenabholungState() {
        let state = (!this.isRampenabholung && this.selectedManufacturerId > -1) ||
            (this.isRampenabholung && this.rampeManufacturerId > -1 && this.selectedManufacturerId == this.rampeManufacturerId);
        this.$store.commit('setRampenabholungSelectionValid', state)
    }
    
    private isRampenabholungAvailable(manufacturerId: any) {
        let offer = this.manufacturingOffers.filter((m:any) => m.manufacturerId == manufacturerId)[0];
        let fertiger = this.manufacturerAddresses.filter((m:any) => m.manufacturerId == manufacturerId)[0];
        if (!offer || !fertiger) return false;
        return offer.canProcessTheOrder && fertiger.isRampenabholungAvailable
    }
    
    private getFertigerInfoById(manufacturerId: number) {
        var address = this.manufacturerAddresses
            .find((addr: FertigerAddressDto) => addr.manufacturerId === manufacturerId);
        if (address === undefined) {
            return new FertigerAddressDto();
        }
        return address;
    }

    private getFertigerTownById(manufacturerId: number) {
        var address = this.getFertigerInfoById(manufacturerId);
        return address.town;
    }

    private openManufacturerAddressDialog(fertiger: any) {
        this.currentFertigerAddress = this.getFertigerInfoById(fertiger.manufacturerId) as FertigerAddressDto;
        this.isManufacturerAddressDialogVisible = true;
    }

}

