var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-card",
              { attrs: { elevation: hover || _vm.selected ? 12 : 2 } },
              [
                _c(
                  "v-card-text",
                  [
                    _vm.currentOffer.orderDiscounts.length > 1
                      ? _c(
                          "v-row",
                          { staticStyle: { height: "96px" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          (_vm.selected ||
                                            hover ||
                                            !_vm.anySelected) &&
                                          _vm.allowedDates.length > 0,
                                        expression:
                                          "(selected || hover || !anySelected) && allowedDates.length > 0",
                                      },
                                    ],
                                    attrs: {
                                      "x-large": "",
                                      fab: "",
                                      color:
                                        _vm.selected ||
                                        (hover && !_vm.anySelected)
                                          ? "accent"
                                          : "auto",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.selected = true
                                        _vm.anySelected = true
                                        _vm.$emit(
                                          "selectedChanged",
                                          _vm.priceModel
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !_vm.anySelected
                                      ? _c("v-icon", [_vm._v("mdi-help")])
                                      : _c("v-icon", [_vm._v("mdi-check")]),
                                  ],
                                  1
                                ),
                                !_vm.anySelected && _vm.allowedDates.length > 0
                                  ? _c("p", [_vm._v("Bitte auswählen")])
                                  : _vm._e(),
                                _vm.allowedDates.length == 0
                                  ? _c("p", [_vm._v("Aktuell nicht verfügbar")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-container",
                              { staticClass: "ma-0 pa-0" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _vm.currentOffer.orderDiscounts.length > 1
                                      ? _c("v-col", [
                                          _c("h3", { staticClass: "text-h5" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.priceModel == 0
                                                    ? "Schnelle Lieferung"
                                                    : "Günstigster Preis"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "blexon-button",
                                            attrs: {
                                              icon: "",
                                              title:
                                                "Angebot als PDF-Dokument herunterladen",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadOffer()
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(" mdi-printer "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-simple-table", {
                              staticClass: "mb-5 offer",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [_vm._v(" Position ")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-right" },
                                              [_vm._v(" Preis ")]
                                            ),
                                          ]),
                                        ]),
                                        _c("tbody", [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(" Lieferdatum (ab Werk) "),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.allowedDates,
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    color: "accent",
                                                    "no-data-text":
                                                      "Keine Liefertermine verfügbar",
                                                    "prepend-icon":
                                                      "mdi-calendar-range",
                                                    "single-line": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function (data) {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatDate"
                                                                  )(data.item)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatDate"
                                                                  )(data.item)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.deliveryDate,
                                                    callback: function ($$v) {
                                                      _vm.deliveryDate = $$v
                                                    },
                                                    expression: "deliveryDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("td", [
                                              _vm.amountOfPositionsInCart > 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.amountOfPositionsInCart
                                                        ) +
                                                        " Positionen im Warenkorb "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Position im Warenkorb "
                                                    ),
                                                  ]),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("round2Decimal")(
                                                      _vm.totalOfCartPositions
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.waehrung)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              [
                                                _vm._v(" Auftragsrabatt "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "accent",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.isAuftragsrabattDialogVisible = true
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-help-circle ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("format2Decimal")(
                                                      _vm.orderDiscount.discount
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.waehrung)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "tr",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isRampenabholung,
                                                  expression:
                                                    "!isRampenabholung",
                                                },
                                              ],
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  "Verpackung & Lieferung " +
                                                    _vm._s(_vm.lieferArtString)
                                                ),
                                              ]),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm.isDeliveryNachAufwand
                                                    ? _c("span", [
                                                        _vm._v("nach Aufwand"),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "format2Decimal"
                                                            )(_vm.deliveryCost)
                                                          ) +
                                                            " " +
                                                            _vm._s(_vm.waehrung)
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.invoiceCharge > 0
                                            ? _c("tr", [
                                                _c("td", [
                                                  _vm._v("Rechnung per Post"),
                                                ]),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "format2Decimal"
                                                        )(_vm.invoiceCharge)
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.waehrung)
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-subtitle-1",
                                              },
                                              [_vm._v("Netto")]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-subtitle-1 text-right",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("format2Decimal")(
                                                      _vm.nettoOffer
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.waehrung)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                "MwSt. (" +
                                                  _vm._s(_vm.mwstRatio) +
                                                  "%)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("format2Decimal")(
                                                      _vm.mwstCost
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.waehrung)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-subtitle-1 last",
                                              },
                                              [_vm._v("Total (inkl. MwSt.)")]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-subtitle-1 text-right last",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("round2Decimal")(
                                                      _vm.totalOffer
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.waehrung)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm.selected
                              ? _c(
                                  "v-container",
                                  { attrs: { fluid: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _vm.isDeliveryNachAufwand &&
                                            _vm.canOrderDirectly &&
                                            !_vm.isRampenabholung
                                              ? _c("v-checkbox", {
                                                  attrs: {
                                                    color: "accent",
                                                    "hide-details": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Ich bin mir bewusst, dass zusätzliche Kosten für Verpackung & Lieferung entstehen"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "accent",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      _vm.isNachAufwandDialogVisible = true
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-help-circle "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.acceptedNachAufwand,
                                                    callback: function ($$v) {
                                                      _vm.acceptedNachAufwand =
                                                        $$v
                                                    },
                                                    expression:
                                                      "acceptedNachAufwand",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.canOrderDirectly
                                              ? _c("v-checkbox", {
                                                  attrs: {
                                                    color: "accent",
                                                    "hide-details": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Ich bin mit den "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v("AGB"),
                                                              ]),
                                                              _vm._v(
                                                                " einverstanden"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  right: "",
                                                                  color:
                                                                    "accent",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.navigateToAgb()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-help-circle "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.acceptedAGB,
                                                    callback: function ($$v) {
                                                      _vm.acceptedAGB = $$v
                                                    },
                                                    expression: "acceptedAGB",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.canOrderDirectly
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mt-3 blexon-button",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.readyToBuy ||
                                                        _vm.isLoading,
                                                      title:
                                                        "Angebot kostenpflichtig bestellen",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.createOrder(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.isLoading
                                                      ? _c(
                                                          "v-progress-circular",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              size: "15",
                                                              indeterminate: "",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" Kaufen "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.canOrderIndirectly &&
                                            !_vm.canOrderDirectly
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "blexon-button",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.readyToBuy ||
                                                        _vm.isLoading,
                                                      title:
                                                        "Die Bestellung wird an die verantwortliche Person in Ihrer Organisation zur Freigabe weitergeleitet.",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.createOrder(
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.isLoading
                                                      ? _c(
                                                          "v-progress-circular",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              size: "15",
                                                              indeterminate: "",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " Bestellung beantragen "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isOfferTimerZero
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 text-caption red--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "- Der Angebotstimer ist abgelaufen"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.offerInvalidForCartUpdate
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 text-caption red--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " - Das Angebot ist nicht mehr gültig, da ein Artikel im Warenkorb aktualisiert wurde. Bitte berechnen Sie das Angebot neu. "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.acceptedAGB &&
                                            _vm.canOrderDirectly
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 text-caption red--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "- Die AGBs müssen akzeptiert werden"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isDeliveryNachAufwand &&
                                            !_vm.acceptedNachAufwand &&
                                            _vm.canOrderDirectly &&
                                            !_vm.isRampenabholung
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 text-caption red--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " - Die Lieferkosten nach Aufwand müssen akzeptiert werden "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.isDeliveryDateValid
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 text-caption red--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "- Es muss ein Lieferdatum ausgewählt werden"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "500" },
                        model: {
                          value: _vm.isAuftragsrabattDialogVisible,
                          callback: function ($$v) {
                            _vm.isAuftragsrabattDialogVisible = $$v
                          },
                          expression: "isAuftragsrabattDialogVisible",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _vm._v("Wann erhalte ich Auftragsrabatt?"),
                            ]),
                            _c("v-card-text", [
                              _vm._v(
                                " Bei der Berechnung eines Angebots werden alle darin enthalten Positionen berücksichtigt und es wird ein Auftragsrabatt kalkuliert."
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(" Folgende Faktoren führen zu einer "),
                              _c("b", { staticStyle: { color: "#AA6200" } }, [
                                _vm._v("Preisreduktion:"),
                              ]),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "Mehrere Artikel aus dem gleichen Rohmaterial (gleicher Werkstoff und gleiche Blechdicke)"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Mehrere Artikel mit den gleichen Bearbeitungen (Gewinde, Senkungen und Durchzüge) in gleichem Rohmaterial"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Mehrere Artikel mit gleicher Pulverbeschichtung (gleiches Pulver)"
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isAuftragsrabattDialogVisible = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Schliessen ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "500" },
                        model: {
                          value: _vm.isNachAufwandDialogVisible,
                          callback: function ($$v) {
                            _vm.isNachAufwandDialogVisible = $$v
                          },
                          expression: "isNachAufwandDialogVisible",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _vm._v("Transport nach Aufwand"),
                            ]),
                            _c("v-card-text", [
                              _c("p", [
                                _vm._v(
                                  "Wir berechnen die Kosten für Verpackung und Versand per Post- oder LKW wenn möglich automatisch."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Auf Sperrgutversand per Post verzichten wir bewusst, da der Verpackungsaufwand bei dieser Versandart meistens unverhältnismässig hoch ist."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Grosse und/oder schwere Teile werden per LKW geliefert. Hier ist eine automatische Berechnung im Voraus nicht immer möglich."
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Der Preis ist abhängig vom Verpackungsaufwand, der Distanz zum Lieferort, dem Gewicht der Teile und von der Abmessung aller Teile der gesamten Lieferung."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Bei grossen Teilen (grösser als eine Europalette) fällt die Abmessung preislich sehr ins Gewicht. Die Lieferung wird mit jedem zusätzlichen Zentimeter teurer. Gleichzeitig ist es sehr schwierig, automatisch zu berechnen, wie sich Biegeteile stapeln lassen und welche Verpackung notwendig ist, um einen sicheren Transport zu gewährleisten."
                                ),
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    "Wir berechnen ihnen deshalb bei Lieferungen nach Aufwand den tatsächlichen Betrag, welcher unser Spediteur gemäss Tarif ASTAG verlangt. Zusätzlich berechnen wir den Zeitaufwand für das Verpacken auf die Minute genau."
                                  ),
                                  _c("br"),
                                ]),
                                _vm._v(
                                  " Sie können davon ausgehen, dass eine Lieferung per LKW nach Aufwand in der Regel zwischen " +
                                    _vm._s(_vm.waehrung) +
                                    " 100.- und 200.- zu liegen kommt."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Bei sehr grossen Sendungen, z.B. mehrere Europaletten oder grossflächigen Sendungen (Länge x Breite > 200cm x 100cm), kann der Preis höher ausfallen. Kontaktieren Sie im Zweifelsfall unser Helpdesk auf "
                                ),
                                _c(
                                  "a",
                                  { attrs: { href: "mailto:info@blexon.com" } },
                                  [_vm._v("info@blexon.com")]
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Wir sind uns bewusst, dass es für unsere Kunden wichtig ist, den Endpreis, inklusive aller Kosten, vor der Bestellung zu kennen. Technische Limitationen machen das im Moment in gewissen Fällen unmöglich. Wir haben uns bewusst gegen eine Pauschale entschieden, weil wir überzeugt sind, mit dieser Regelung eine transparentere und kosteneffizientere Lösung, welche der Realität entspricht, zu bieten."
                                ),
                              ]),
                            ]),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isNachAufwandDialogVisible = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Schliessen ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-dialog",
                          {
                            attrs: { width: "500" },
                            model: {
                              value: _vm.isOrderFailedDialogVisible,
                              callback: function ($$v) {
                                _vm.isOrderFailedDialogVisible = $$v
                              },
                              expression: "isOrderFailedDialogVisible",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _vm._v("Bestellung fehlgeschlagen"),
                                ]),
                                _c("v-card-text", [
                                  _vm._v(
                                    " Während dem Bestellvorgang ist ein Fehler aufgetreten."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Kontaktieren Sie für Unterstützung unser Helpdesk auf "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "mailto:info@blexon.com" },
                                    },
                                    [_vm._v("info@blexon.com")]
                                  ),
                                ]),
                                _c("v-divider"),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.isOrderFailedDialogVisible = false
                                          },
                                        },
                                      },
                                      [_vm._v(" Schliessen ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }