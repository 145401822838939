var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-0" }, [
            _vm.currentOffer.orderDiscounts.length > 1 &&
            _vm.selectedPriceModel == null
              ? _c("h1", { staticClass: "text-h4" }, [
                  _vm._v("Wählen Sie Ihr Angebot"),
                ])
              : _c("h1", { staticClass: "text-h4" }, [_vm._v("Ihr Angebot")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pl-3" },
        _vm._l(_vm.currentOffer.orderDiscounts, function (discount, index) {
          return _c(
            "v-col",
            {
              key: index,
              attrs: {
                cols: "12",
                sm: 12 / _vm.currentOffer.orderDiscounts.length,
                md: 10 / _vm.currentOffer.orderDiscounts.length,
                lg: 8 / _vm.currentOffer.orderDiscounts.length,
              },
            },
            [
              _c("offer-component", {
                ref: discount.priceModel,
                refInFor: true,
                attrs: { priceModel: discount.priceModel },
                on: {
                  selectedChanged: function ($event) {
                    return _vm.changeSelection($event)
                  },
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }