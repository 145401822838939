import { CatalogueSortingType } from "./../dtos/catalogueSortingType";
import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import Oidc from "oidc-client";
import { OfferFailReason } from "@/dtos/offerFailReason";

Vue.use(Vuex);
const authorityUrl = process.env.VUE_APP_authorityUrl;
const redirectUri = process.env.VUE_APP_redirectUri;
const postLogoutRedirectUri = process.env.VUE_APP_postLogoutRedirectUri;

// STATE
const state = {
	// ------ GLOBAL ------

	// Application version from package.json file
	packageJson: JSON.parse(unescape(process.env.PACKAGE_JSON || "%7B%7D")),
	// Boolean für globalen Ladebalken unter der Header-Leiste
	loading: false,
	
	// ------ SNACKBAR ------

	// Die Snackbar
	snackbar: { 
		text: '', 
		color: 'accent',
		articleId: 0, // wenn angegeben -> Thumbnail des Artikels in Snackbar
	},
	// If ture, the snackbar is shown
	showSnackbar: false,
	// Timeout für snackbars (Wie lange sie angezeigt werden sollen)
	snackbarTimeout: 6000, // 6 Sekunden

	// ------ BENACHRICHTIGUNGS-BANNER ------

	// Broadcasted Störungsmeldung
	bannerErrorMessage: '',

	// ------ CUSTOMER ------

	// [CustomerInfoDto] Customer mit den nötigen Informationen fürs display im Client (Kundennummer / Lieferadressen...)
	customer: null,
	// These are the users for the article filter. This array includes the dummy entry to filter for all users.
	users: [],
	// Settings für das Paging der Kunden
	pagination: {
		sortBy: "registriertAm",
		descending: true,
		rowsPerPage: 100,
	},
	isAdditionalEmailSelected: false,
    additionalOrderConfirmationEmail: '',
	unseenHintIds: [],


	// ------ ARTICLE CATALOGUE ------

	// [CustomerArticleDto] Berechnete Katalogartikel des eingeloggten Kunden
	articles: [],
	// [CustomerArticleDto] Artikel, welche noch mit gewähltem Werkstoff berechnet werden müssen
	importingArticles: [],
	// [number[]] Ids von Artikeln, welche im Katalog derzeit angezeigt werden
	catalogueArticles: [],
	// [number[]] Ids von Artikeln, welche momentan ausgewählt sind (multi-selection)
	selectedArticleIds: [],
	// [string] Catalogue Suchbegriff. Ist im state, damit Katalogartikel gefiltert angezeigt werden können
	catalogueSearchTerm: "",
	// [number] Value des Enums für die aktuelle Sortierung der Artikel im Katalog
	activeCatalogueSortTypeValue: CatalogueSortingType.LastChangeDescending,
	// [string] User id of currently selected user in the catalogue filter
	catalogueUserId: '-1',
	// [WerkstoffDto] Werkstoffe in genereller Form (z.B. Aluminium)
	werkstoffe: [],
	// [boolean] Indicates whether powder coating is available in the region
	isPowderCoatingAvailable: false,
	// Id welche beim Import kurz vergeben wird, bis die Blexon Id eintrifft.
	// so werden nicht eindeutige Ids vermieden.
	temporaryImportId: -1,
	// [FileSizeLimit[]] Grenzen für Grösse von Dateitypen für den Artikelupload.
	fileSizeLimits: [],
	// [PowderDto[]] Powders for powder coating.
	powders: [],
	// [boolean] Indicates whether or not there is more to load in the catalogue
	hasMoreArticlesToLoad: true,
	// [number] The id of the timer that polls calculating articles if there are any.
	articleCalculatingPollingTimerId: null,

	// ------ ORDERS / BESTELLUNGEN ------

	// [CustomerOrderDto] Bestellungen des Kunden als OrderTrackings
	orders: [],
	// Settings für das Pageing der Aufträge
	orderSorting: {
		sortBy: "receivedAt",
		descending: true,
		rowsPerPage: 30,
	},
	// [OrderCancellationRequestDto[]] Open cancellation requests for this customer
	orderCancellations: null,

	// ------ ORDER PROCESS ------

	// [PendingOrderDto] Offene Bestellungen welche noch nicht definitiv bestellt wurden.
	pendingOrders: [],
	// [Boolean] True wenn Auftragserstellung erfolgreich abgeschlossen. Benutzt für entsprechende Anzeige
	orderSuccessful: false,
	// [Boolean] True wenn das Angebot zurzeit berechnet wird.
	currentOfferCalculating: false,
	// [Boolean] Can be toggled to refresh addresses in the cart.
	refreshCartAddresses: false,

	// ------ CART ------

	// [Cart] -> [CartItems] Warenkorb mit allen darin enthaltenen Artikeln / Adressen
	currentCart: null,
	// [SavedCart] -> [positions: SavedCartPosition] Stücklisten des Kunden (ehem. gespeicherte Warenkörbe)
	savedCarts: null,
	// Id der zuletzt ausgewählten Stückliste
	currentSavedCartId: 0,
	
	// ------ OFFER ------

	// [OfferDto] Berechnete Offerte für die Artikel im Warenkorb. Enthält die CalculationId
	offer: null,
	// [Boolean] True wenn Angebotserstellung fehlgeschlagen. Benutzt für entsprechende Anzeige
	offerFailed: false,
	// [OfferFailReason] The reason why the order failed.
	offerFailReason: OfferFailReason.None,
	// How many seconds in total the offer is valid (1h --> 3600sec)
	offerValidTimerTotalSeconds: 900,
	// How many seconds longer is the current offer valid. If 0 --> invalid offer
	offerValidTimerSeconds: 0,
	// [number] Offer timer interval id. Has to be handled here otherwise context is lost.
	offerDecrementTimerId: 0,
	// [PriceModel] Currently selected price model. Defaults to "Standard" (= 0).
	currentPriceModel: 0,
	// [Boolean] If an article in the cart is updated the current offer is invalidated because it could show wrong information.
	offerInvalidForCartUpdate: false,
	// [Boolean] The customer has accepted the agb.
	acceptedAGB: false,
	// [Boolean] The customer has accepted the the unknown transport cost.
	acceptedNachAufwand: false,
	// [Boolean] Indicating if the customer chose Rampenabholung but didn't make a valid selection.
	rampenabholungSelectionValid: false,

	oidc: {
		user: null,
		authenticated: false,
		instance: null,
		token: null,
		config: {
			//@ts-ignore
			userStore: new Oidc.WebStorageStateStore(),
			authority: authorityUrl,
			// Client application's identifier as registered on the Identityserver
			client_id: "BlexonKundenportalClient",
			// Redirect URI of your client application to receive a response from the Identityserver
			redirect_uri: redirectUri,
			// The Identityserver post-logout redirect URI.
			post_logout_redirect_uri: postLogoutRedirectUri,
			// The type of response desired from the Identityserver
			response_type: "id_token token",
			// The scope being requested from the Identityserver
			scope: "openid profile KundenportalApi EventApi offline_access",
			silent_redirect_uri: window.location.origin + "/silent-renew.html",
			accessTokenExpiringNotificationTime: 601200, // 7 Tage minus 1 Stunde
			automaticSilentRenew: true,
			filterProtocolClaims: true,
			loadUserInfo: true,
		},
	},
};

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
});
