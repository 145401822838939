
import { OfferDto } from '@/dtos/offerDto';
import { Component, Vue } from 'vue-property-decorator';
import { ManufacturingOfferDto } from '@/dtos/manufacturingOfferDto';
import { OfferFailReason } from '@/dtos/offerFailReason';

@Component({
    components: {
    }
})
export default class SummaryComponent extends Vue {
    private get isCartEmpty() {
        return this.$store.getters.amountOfItemsInCart <= 0;
    }
    private get offerFailed() {
        return this.$store.state.offerFailed;
    }
    private get currentOffer() {
        return this.$store.state.offer as OfferDto;
    }
    private get offerFailReason() {
        return this.$store.state.offerFailReason as OfferFailReason;
    }
    private get isCountryValidForDelivery() {
        return this.$store.getters.customerIsCountryValidForDelivery;
    }
    private get offerCalculating() {
        return this.$store.state.currentOfferCalculating;
    }
    private get wasOrderedSuccessfully() {
        return this.$store.state.orderSuccessful;
    }
    private get offerHasNoValidManufacturingOffers() {
        if (this.currentOffer !== null && (this.currentOffer.manufacturingOffers.length <= 0
            || this.currentOffer.manufacturingOffers.every((o: ManufacturingOfferDto) => o.canProcessTheOrder === false))) {
            return true;
        }
        return false;
    }
    private get offerHasNoFailedState() {
        console.log(this.offerFailReason);
        return this.offerFailReason == OfferFailReason.None;
    }
    private get noFertigerCanProduce() {
        return this.offerFailReason == OfferFailReason.NoFertigerCanProduce;
    }
    private get noFertigerHasDeliveryDate() {
        return this.offerFailReason == OfferFailReason.NoFertigerHasDeliveryDate;
    }
    private redirectToArticles(){
        this.$router.push({ name: 'Catalogue' });
    }

    private get validTimerSeconds(){
        return this.$store.state.offerValidTimerSeconds;
    }
    private get validTimerPercent(){
        let percent = (this.$store.state.offerValidTimerSeconds / this.$store.state.offerValidTimerTotalSeconds) * 100;
        return percent;
    }
    private get offerInvalidForCartUpdate() {
        return this.$store.state.offerInvalidForCartUpdate;
    }    
    private get canOrderDirectly() {
        return this.$store.state.customer.user.canOrderDirectly;
    }

    private get userEmail() {
        if (this.$store.state.oidc !== undefined) {
            return this.$store.state.oidc.user.profile.name;
        }
        return "";
    }
}

