var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.offerCalculating
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  size: "50",
                                  color: "accent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("p", { staticClass: "ma-0" }, [
                                _vm._v(" Ihr Angebot wird berechnet..."),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " Lieferkosten und Liefertermine werden mit den Fertigungspartnern bestimmt."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Bei vielen Positionen kann das ein bis zwei Minuten dauern. "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.wasOrderedSuccessfully
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { "x-large": "", color: "accent" } },
                                [_vm._v("mdi-check-decagram")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _vm.canOrderDirectly
                                ? _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        "Vielen Dank für Ihre Bestellung."
                                      ),
                                    ]),
                                    _c("br"),
                                    _vm._v(
                                      " Sie können den Status des Auftrags von nun an in der Bestellübersicht einsehen. Ebenfalls werden sie in Kürze eine Bestätigungsemail erhalten. "
                                    ),
                                  ])
                                : _c("p", [
                                    _c("strong", [
                                      _vm._v("Vielen Dank für Ihren Auftrag."),
                                    ]),
                                    _c("br"),
                                    _vm._v(
                                      " Die verantwortlichen Personen in Ihrer Organisation werden in Kürze per E-Mail über Ihren Auftrag informiert. Diese Personen können die Bestellung, bei Bedarf, anpassen und anschliessend freigeben. Sobald Ihr Bedarf bestellt wurde, können Sie den Status des Auftrags in der Bestellübersicht einsehen. Ebenfalls erhalten Sie dann eine Bestätigungsemail. "
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blexon-button",
                                  attrs: {
                                    title: "Navigiert zum Artikelkatalog.",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectToArticles()
                                    },
                                  },
                                },
                                [_vm._v(" Zu meinen Artikeln ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isCountryValidForDelivery
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { "x-large": "", color: "accent" } },
                                [_vm._v("mdi-alert-decagram")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("p", { staticClass: "ma-0" }, [
                                _vm._v(" Angebot nicht möglich"),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " Leider beliefern wir zur Zeit noch keine Kunden in Ihrem Land."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Sobald sich das ändert, werden wir Sie per Newsletter informieren. "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.offerFailed
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { "x-large": "", color: "accent" } },
                                [_vm._v("mdi-alert-decagram")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _vm.noFertigerCanProduce
                                ? _c("p", { staticClass: "ma-0" }, [
                                    _vm._v(
                                      " Es können nicht alle Positionen des Warenkorbs an einem einzigen Standort produziert werden,"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " da sich Rohmaterialien und/oder Bearbeitungen ausschliessen."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " In der Regel kann der Auftrag gefertigt werden, wenn die Positionen entsprechend aufgeteilt werden."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Unser Helpdesk sucht nach einer geeigneten Aufteilung für Ihren Warenkorb und wird Sie unter " +
                                        _vm._s(_vm.userEmail) +
                                        " informieren."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Bitte entschuldigen Sie die Verzögerung. "
                                    ),
                                  ])
                                : _vm.noFertigerHasDeliveryDate
                                ? _c("p", { staticClass: "ma-0" }, [
                                    _vm._v(
                                      " Aufgrund hoher Auslastung konnten keine Liefertermine für Ihren Auftrag gefunden werden."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Unser Helpdesk wurde bereits informiert und wird sich bei Ihnen melden. "
                                    ),
                                  ])
                                : _c("p", { staticClass: "ma-0" }, [
                                    _vm._v(
                                      " Angebot konnte nicht erstellt werden"
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      " Leider ist bei der Erstellung Ihres Angebots ein Fehler aufgetreten."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Unser Helpdesk wurde darüber informiert und meldet sich baldmöglichst bei Ihnen."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Bei Fragen kontaktieren Sie bitte unser Helpdesk auf "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "mailto:info@blexon.com",
                                        },
                                      },
                                      [_vm._v("info@blexon.com")]
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.currentOffer != null
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentOffer.orderDiscounts.length > 1
                            ? "Ihre Angebote sind "
                            : "Ihr Angebot ist"
                        ) +
                        " 15 Minuten lang gültig. Nach Ablauf der Gültigkeit können Sie eine Neuberechnung starten. "
                    ),
                  ]),
                  _vm.validTimerSeconds > 0 && !_vm.offerInvalidForCartUpdate
                    ? _c("v-progress-linear", {
                        staticClass: "mb-2",
                        attrs: {
                          value: _vm.validTimerPercent,
                          color: "accent",
                          height: "25",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ value }) {
                                return [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(Math.ceil(value)) +
                                          "% - " +
                                          _vm._s(
                                            _vm._f("format2Digits")(
                                              Math.floor(
                                                _vm.validTimerSeconds / 60
                                              )
                                            )
                                          ) +
                                          "min " +
                                          _vm._s(
                                            _vm._f("format2Digits")(
                                              _vm.validTimerSeconds % 60
                                            )
                                          ) +
                                          "sec"
                                      ),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          992231459
                        ),
                      })
                    : _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: { title: "Berechnet das Angebot erneut." },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("recalculate-offer")
                            },
                          },
                        },
                        [_vm._v(" Neu berechnen ")]
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { "x-large": "", color: "accent" } },
                                [_vm._v("mdi-alert-decagram")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("p", { staticClass: "ma-0" }, [
                                _vm._v(" Angebot konnte nicht erstellt werden"),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " Leider ist bei der Erstellung Ihres Angebots ein Fehler aufgetreten."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Kontaktieren Sie bitte unser Helpdesk auf "
                                ),
                                _c(
                                  "a",
                                  { attrs: { href: "mailto:info@blexon.com" } },
                                  [_vm._v("info@blexon.com")]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }