
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import rules from '@/store/rules'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { ArticleState } from '@/dtos/articleState';
import { CartItem } from '@/dtos/cartItem';
import { PriceState } from '@/dtos/priceState';
import { TokenResponseDto } from '@/dtos/tokenResponseDto';
import ArticlePriceInfoDialog from '@/views/Dialogs/ArticlePriceInfoDialog.vue'
import ArticleEditDialog from '@/views/Dialogs/ArticleEditDialog.vue'
import ArticleDeleteWarningDialog from '@/views/Dialogs/ArticleDeleteWarningDialog.vue'

@Component({
    components: {
		ArticlePriceInfoDialog,
        ArticleEditDialog,
        ArticleDeleteWarningDialog,
    }
})
export default class AssemblyTile extends Vue {
    @Prop()
    private articleId!: number;
    private cadDialog: boolean = false;
    private currentWindowHeight: string = '100px';
    private amountToAddToCart: number = 1;
    private articleEditDialog: boolean = false;
    private articleDeleteWarningDialog: boolean = false;
    private blexonisierenRunning: boolean = false;
    private isValidAmount: boolean = true;
    private deleteOverlay: boolean = false;
    private isUpdatingArticleData: boolean = false;
    private allowedCartAmountRule = rules.allowedCartAmountRule;
    private articleToken: TokenResponseDto | null = null;
    private articleCartNames: string[] = [];

    private mounted() {
        this.$store.dispatch('loadMissingArticles', this.article.subArticleIds);
        this.setWindowHeight();
        window.addEventListener('resize', event => {
            this.setWindowHeight();
        });
    }

    private get article() : CustomerArticleDto{
        return this.$store.state.articles
            .find((a: CustomerArticleDto) => a.articleId == this.articleId);
    }

    private get region() {
        return this.$store.getters.customerDefaultDeliveryAddress.country;
    }

    private get gewicht() {
        return this.subArticles.reduce((previousValue, a) => {
            return previousValue + a.weight * a.quantityInAssembly;
        }, 0);
    }

    private get isMultiSelectionMode() {
        return this.$store.state.selectedArticleIds.length > 0;
    }

    private navigateTo(link:string) {
        this.$router.push({ path: link });
    }
    private get articleCadViewUrl() {
        const email = encodeURI(this.$store.state.customer.user.username);
        const url = process.env.VUE_APP_cadClientUrl + `artikel?id=${this.article.articleId}` +
            `&token=${this.articleToken?.token}&readonly=${this.articleToken?.readonly}` +
            `&defaultEMail=${email}&locked=${this.article.locked}&weld=${this.isWeldingAvailable}`;
        return url;
    }

    private get isWeldingAvailable() {
        return this.$store.state.isWeldingAvailable;
    }
    
    private get isCartButtonDisabled() {
        if (!this.isValidAmount || !this.state.canBeProduced || this.state.calculating ||
            (this.state.state !== ArticleState.OK && this.state.state !== ArticleState.Warning) ||
            this.state.priceState !== PriceState.Ok) {
            return true;
        }
        return false;
    }

    private setUpdating(value: boolean) {
        this.isUpdatingArticleData = value;
    }

    private setDeleteOverlay(value: boolean) {
        this.deleteOverlay = value;
    }

    private getThumbnailUrl(id: string) {
        return this.$store.getters.getThumbnailUrl(id, this.article.lastChange);
    }

    private get isArticleSelected() {
        return this.$store.state.selectedArticleIds.includes(this.articleId);
    }
    private set isArticleSelected(newValue: boolean) {
        // the new value is irrelevant. We just toggle the checkbox.
        this.$store.commit('addOrRemoveSelectedArticleId', this.articleId);
    }

    private async openCadDialog() {
        try {
            const response = await httpClient().post(`article/GetArticleToken?articleId=${this.articleId}`);
            this.articleToken = response.data;
            if (this.articleToken != null) {
                window.addEventListener('message', this.handleArticleChangedInCad);
                this.cadDialog = true;
            }
        } catch(err) {
            console.error(`Für den Artikel ${this.articleId} konnte kein Token geladen werden. Error: ${err}`);
        }
    }

    private openArticleEditDialog() {
        this.articleEditDialog = true;
    }

    /**
     * This event handler is called, when the cad article in the iframe has changed.
     */
    private handleArticleChangedInCad (event: any) {
        if (event.origin == 'https://cad.test.blexon.com' || 
            event.origin == 'https://cad.blexon.com' ||
            event.origin == 'http://localhost:8180') {

            if (event.data == `article-${this.articleId}-closed`) {
                this.closeCadDialog(true);
            } else if (event.data == `article-${this.articleId}-saved`) {
                this.closeCadDialog(false);
            }
        }
    }

    private async closeCadDialog(deleteToken: boolean) {
        this.cadDialog = false;
        window.removeEventListener('message', this.handleArticleChangedInCad);
        // delete token only when the article was not saved.
        // Wenn der Artikel gespeichert wurde, wird das Token nach der Validierung gelöscht.
        if (deleteToken) {
            await httpClient().post(`article/DeleteToken?token=${this.articleToken?.token}`);
        }
        this.articleToken = null;
    }

    private addToCart() {
        for (const subarticle of this.subArticles) {
            let newCartItem = new CartItem();
            newCartItem.articleId = subarticle.articleId;
            newCartItem.quantity = subarticle.quantityInAssembly * this.amountToAddToCart;
            this.$store.dispatch("addToCart", newCartItem);
        }
        this.$store.dispatch(
            'setSnackbarArticleText', 
            { 
                text: `${this.amountToAddToCart} Stück in den Warenkorb gelegt.`, 
                articleId: this.articleId 
            }
        );
    }

    private async checkDeleteArticle() {        
        try {
            this.articleCartNames = [];
            const response = await httpClient().get(`Cart/GetCartsOfArticle?articleId=${this.articleId}`);
            if (response.data.length > 0) {
                this.articleCartNames = response.data.sort();
            }
        } catch(err) {
            console.error(`Could not retrieve carts of article ${this.articleId}.`);
        } finally {
            this.articleDeleteWarningDialog = true;
        }
    }

    private setWindowHeight () {
        // 165 besteht aus...
        // - Margins der Dialogbox (variieren etwas je nach Aspect Ratio)
        // - Buttons für Speichern / Schliessen im Dialogfenster
        // - Sicherheitsmarge, sodass sicher keine Scrollbar im Dialog erscheint
        const currentHeight = window.innerHeight - 65
        this.currentWindowHeight = `${currentHeight}px`
    }

    private get subArticles() : CustomerArticleDto[] {
        const subArticles = this.$store.state.articles.filter((a:CustomerArticleDto) => a.parentArticleId == this.articleId);
        return subArticles;
    }

    private get state() {
        const locked : boolean = this.subArticles.some((a: CustomerArticleDto) => a.locked);
        let state = ArticleState.OK;
        if (this.subArticles.length <= 0 || this.subArticles.some((a: CustomerArticleDto) => a.state == ArticleState.Error)) {
            state = ArticleState.Error;
        } else if (this.subArticles.some((a: CustomerArticleDto) => a.state == ArticleState.Info)) {
            state = ArticleState.Info;
        } else if (this.subArticles.some((a: CustomerArticleDto) => a.state == ArticleState.Warning)) {
            state = ArticleState.Warning;
        }
        const calculating = this.subArticles.some((a: CustomerArticleDto) => a.calculating || a.priceState == PriceState.Calculating);
        let priceState = PriceState.Ok;
        if (this.subArticles.some((a: CustomerArticleDto) => a.priceState == PriceState.NotCalculated || a.priceState == PriceState.Invalid || a.priceState == PriceState.Failed)) {
            priceState = PriceState.Invalid;
        } else if (this.subArticles.some((a: CustomerArticleDto) => a.priceState == PriceState.Calculating)) {
            priceState = PriceState.Calculating;
        }
        const canBeProduced = this.subArticles.every((a: CustomerArticleDto) => a.canBeProduced);
        return {
            locked: locked,
            state: state,
            priceState: priceState,
            calculating: calculating,
            canBeProduced: canBeProduced,
        }
    }

    private get articleStateText() {
        if(this.state.locked) {
            return 'In Überprüfung';
        }
        switch(this.state.state) { 
            case ArticleState.OK: { 
                return 'OK';
            }
            case ArticleState.Warning: { 
                return 'Warnung';
            }
            case ArticleState.Error: { 
                return 'Fehler';
            }
            case ArticleState.Info: { 
                return 'Aktion erforderlich';
            } 
            default: { 
                return 'Unklar';
            } 
        }     
    }
    private get articleStateIcon() {
        if(this.state.locked) {
            return 'mdi-lock';
        }
        switch(this.state.state) { 
            case ArticleState.OK: { 
                return 'mdi-check';
            } 
            case ArticleState.Warning: { 
                return 'mdi-exclamation';
            } 
            case ArticleState.Error: { 
                return 'mdi-close';
            } 
            default: { 
                return 'mdi-help';
            } 
        }     
    }
    private get articleStateColor() {
        if(this.state.locked) {
            return '#CFD8DC';
        }
        switch(this.state.state) { 
            case ArticleState.OK: { 
                return '#C8E6C9';
            }
            case ArticleState.Warning: { 
                return '#FFF9C4';
            }
            case ArticleState.Error: { 
                return '#FFCDD2';
            }
            case ArticleState.Info: { 
                return '#efbb20';
            }
            default: {
                return 'blue';
            }
        }
    }    

}
